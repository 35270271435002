import React, {Component} from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import { FormattedMessage } from 'react-intl';
import LanguageSelector from './language-selector';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';

const renderAbout = (currentLang)=>{
  if (currentLang === "tr") {
    return (<li key={'hakkında'}>
      <Link to={`/tr/about/`} activeClassName="active">
        hakkında
      </Link>
    </li>)
  } else {
    return (<li key={'about'}>
      <Link to={`/about/`} activeClassName="active">
        about
      </Link>
    </li>)
  }
}

class Nav extends Component {
  
  constructor({ isHome, langs, homeLink, url, currentLang }) {
    super();
    this.state = {
      isHome: isHome,
      langs: langs,
      homeLink: homeLink,
      url: url,
      currentLang: currentLang,
      toogle: false,
      mobile: false
    };
  };

  componentDidMount() {
    UIkit.use(Icons);
    this.resetClick();
  }

  resetClick = () => {
    UIkit.offcanvas('#offcanvas').hide();
  }

  handleClick = () => {
    this.setState({
      toogle: !this.state.toogle
    });
  }

  nav = (currentLang) => {
    return (
      <ul className="uk-navbar-nav">
        { renderAbout(this.state.currentLang)}
        <StaticQuery
          query={graphql`
            query {
              allStrapiCategory {
                edges {
                  node {
                    name
                    name_tr
                  }
                }
              }
            }
          `}
          render={data =>
            data.allStrapiCategory.edges.map((category, i) => {
              if (this.state.currentLang === "tr") {
                if (category.node.name.toLowerCase() === "photo") {
                  return (
                    <FormattedMessage id="header.photo" key={category.node.name}>
                      {(photo) => (
                        <li key={category.node.name_tr.toLowerCase()}>
                          <Link to={`/tr/photo/`} activeClassName="active">
                            {photo}
                          </Link>
                        </li>
                      )}
                    </FormattedMessage>
                  )
                } else {
                  return (
                    <li key={category.node.name}>
                      <Link to={`/tr/category/${category.node.name.toLowerCase()}/`} activeClassName="active">
                        {category.node.name_tr.toLowerCase()}
                      </Link>
                    </li>
                  )
                }
              } else {
                if (category.node.name.toLowerCase() === "photo") {
                  return (
                    <FormattedMessage id="header.photo" key={category.node.name}>
                      {(photo) => (
                        <li key={category.node.name.toLowerCase()}>
                          <Link to={`/photo/`} activeClassName="active">
                            {photo}
                          </Link>
                        </li>
                      )}
                    </FormattedMessage>
                  )
                } else {
                  return (
                    <li key={category.node.name}>
                      <Link to={`/category/${category.node.name.toLowerCase()}/`} activeClassName="active">
                        {category.node.name.toLowerCase()}
                      </Link>
                    </li>
                  )
                }
              }
            })
          }
        />
      </ul>
    );
  }

  navMin = () => {
    if (this.state.currentLang === 'tr') {
      return(
        <ul className="uk-nav uk-nav-default">
          <li className="uk-nav-divider"></li>
          <li className="uk-parent"><Link activeClassName="active" className="flex-container" to="/tr/about/"><span className="uk-margin-small-right" data-uk-icon="icon: user"></span><span>Hakkında</span></Link></li>
          <li className="uk-nav-divider"></li>
          <li className="uk-parent">
              <span className="uk-margin-small-right" data-uk-icon="icon: list"></span>Kategoriler
              <ul className="uk-nav-sub">
                  <li><Link activeClassName="active" className="flex-container" to="/tr/category/neruia/"><span className="uk-margin-small-right" data-uk-icon="icon: world"></span>Neruia</Link></li>
                  <li><Link activeClassName="active" className="flex-container" to="/tr/category/blog/"><span className="uk-margin-small-right" data-uk-icon="icon: file-text"></span>Blog</Link></li>
                  <li><Link activeClassName="active" className="flex-container" to="/tr/category/coding/"><span className="uk-margin-small-right" data-uk-icon="icon: laptop"></span>Kod</Link></li>
                  <li><Link activeClassName="active" className="flex-container" to="/tr/category/drawing/"><span className="uk-margin-small-right" data-uk-icon="icon: pencil"></span>Çizim</Link></li>
              </ul>
          </li>
          <li className="uk-nav-divider"></li>
          <li><Link activeClassName="active" className="flex-container" to="/tr/photo"><span className="uk-margin-small-right" data-uk-icon="icon: instagram"></span>Fotoğraf</Link></li>
          <li className="uk-nav-divider"></li>
        </ul>
      );
    } else {
      return(
        <ul className="uk-nav uk-nav-default">
          <li className="uk-nav-divider"></li>
          <li className="uk-parent flex-container"><Link activeClassName="active" className="flex-container" to="/about/"><span className="uk-margin-small-right" data-uk-icon="icon: user"></span>About</Link></li>
          <li className="uk-nav-divider"></li>
          <li className="uk-parent uk-margin-small">
              <span className="uk-margin-small-right" data-uk-icon="icon: list"></span>Categories
              <ul className="uk-nav-sub">
                  <li><Link activeClassName="active" className="flex-container" to="/category/neruia/"><span className="uk-margin-small-right" data-uk-icon="icon: world"></span>Neruia</Link></li>
                  <li><Link activeClassName="active" className="flex-container" to="/category/blog/"><span className="uk-margin-small-right" data-uk-icon="icon: file-text"></span>Blog</Link></li>
                  <li><Link activeClassName="active" className="flex-container" to="/category/coding/"><span className="uk-margin-small-right" data-uk-icon="icon: laptop"></span>Coding</Link></li>
                  <li><Link activeClassName="active" className="flex-container" to="/category/drawing/"><span className="uk-margin-small-right" data-uk-icon="icon: pencil"></span>Drawing</Link></li>
              </ul>
          </li>
          <li className="uk-nav-divider"></li>
          <li><Link activeClassName="active" className="flex-container" to="/photo"><span className="uk-margin-small-right" data-uk-icon="icon: instagram"></span>Photo</Link></li>
          <li className="uk-nav-divider"></li>
        </ul>
      );
    }
  }

  render() {
  return (
      <nav className="uk-navbar-container" data-uk-navbar>
        <div className="uk-navbar-left">
          <ul className="uk-navbar-nav">
            <li>
              <Link data-uk-icon={`icon: home; ratio: 2.75`} to={this.state.homeLink} activeClassName="active"></Link>
            </li>
          </ul>
        <LanguageSelector langs={this.state.langs}></LanguageSelector>
        </div>
        <div className="uk-navbar-right uk-visible@l">
          {this.nav()}
        </div>
        <div className="uk-navbar-right uk-hidden@l">
          <button block="true" data-uk-icon="icon: menu; ratio: 2" onClick={this.handleClick} data-uk-toggle="target: #offcanvas"> </button>
          <div id="offcanvas" data-uk-offcanvas="mode: push; flip: true; overlay: true;">
            <div className="uk-offcanvas-bar">
              {this.navMin()}
            </div>
          </div>
        </div>
      </nav>
      );
  }
}

export default Nav