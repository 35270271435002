import React from "react"
import { Link } from "gatsby"
import PropTypes from 'prop-types';
import styled from 'styled-components';

const LanguageSelector = ({ langs }) => {  
  const links = langs.map(({ langKey, link, selected }) => {
    return(
    <Li key={langKey} className="uk-margin-small-right">
      <Link key={langKey} to={link} selected={selected} className={!selected ? 'unselected-language' : ''}>
        {langKey}
      </Link>
    </Li>)
    });

  return (
    <Ul>
      {links}
    </Ul>
  );
}
export default LanguageSelector

LanguageSelector.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

LanguageSelector.defaultProps = {
  location: {
    pathname: '',
  },
};

const Ul = styled.ul`
  padding-left: 10px;
`;

const Li = styled.li`
  display: inline-block;
  cursor: pointer;
  border-radius: '0.2rem';
  transition: 0.3s;
`;