import React from "react"

const Footer = () => (
  <div>
    <div className="uk-text-center uk-margin-small-bottom uk-margin-small-top uk-visible@m">
      <a href="https://instagram.com/surquwave" target="_blank" rel="noreferrer" aria-label="instagram-icon" uk-icon="icon: instagram; ratio: 2"> </a>
      <a href="https://twitter.com/surqurer" target="_blank" rel="noreferrer" aria-label="twitter-icon" className="uk-margin-medium-left" uk-icon="icon: twitter; ratio: 2"> </a>
      <a href="https://github.com/alpkarli" target="_blank" rel="noreferrer" aria-label="github-icon" className="uk-margin-medium-left" uk-icon="icon: github; ratio: 2"> </a>
      <a href="https://linkedin.com/in/alperen-karlı/" target="_blank" rel="noreferrer" aria-label="lnikedin-icon" className="uk-margin-medium-left" uk-icon="icon: linkedin; ratio: 2"> </a>
      <a href="https://facebook.com/surqurer" target="_blank" rel="noreferrer" aria-label="facebook-icon" className="uk-margin-medium-left" uk-icon="icon: facebook; ratio: 2"> </a>
    </div>
    <div className="uk-text-center uk-margin-small-bottom uk-margin-small-top uk-hidden@m">
      <a href="https://instagram.com/surquwave" target="_blank" rel="noreferrer" aria-label="instagram-icon" uk-icon="icon: instagram; ratio: 1"> </a>
      <a href="https://twitter.com/surqurer" target="_blank" rel="noreferrer" aria-label="twitter-icon" className="uk-margin-medium-left" uk-icon="icon: twitter; ratio: 1"> </a>
      <a href="https://github.com/alpkarli" target="_blank" rel="noreferrer" aria-label="github-icon" className="uk-margin-medium-left" uk-icon="icon: github; ratio: 1"> </a>
      <a href="https://linkedin.com/in/alperen-karlı/" target="_blank" rel="noreferrer" aria-label="lnikedin-icon" className="uk-margin-medium-left" uk-icon="icon: linkedin; ratio: 1"> </a>
      <a href="https://facebook.com/surqurer" target="_blank" rel="noreferrer" aria-label="facebook-icon" className="uk-margin-medium-left" uk-icon="icon: facebook; ratio: 1"> </a>
    </div>
  </div>
)

export default Footer