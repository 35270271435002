import React from "react"
import PropTypes from "prop-types"

//Language
import {
  getLangs,
  getUrlForLang,
  getCurrentLangKey,
  isHomePage,
} from 'ptz-i18n';
import { IntlProvider } from 'react-intl';
import { useStaticQuery, graphql } from "gatsby"

import Nav from "./nav"
import Seo from "./seo"
import Footer from "./footer"

import messages_en from '../translations/en.json';
import messages_tr from '../translations/tr.json';

const Layout = ({children, location}) => {

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author {
              name
            }
            languages {
              langs
              defaultLangKey
            }
          }
        }
      }
    `
  )

  let i18nMessages = messages_en;
  const url = location.pathname;
  const { langs, defaultLangKey } = site.siteMetadata.languages;
  const isHome = isHomePage(url, false, langs);
  const langKey = getCurrentLangKey(langs, defaultLangKey, url);
  
  const homeLink = `/${langKey !== 'en' ? langKey + '/' : ''}`;
  const langsMenu = getLangs(langs, langKey, getUrlForLang(homeLink, url)).map(
    (item) => {
      return ({
      ...item,
      link: item.link.replace(`//`, '/').replace(`/${defaultLangKey}/`, '/'),
    })}
  );

  if (langKey === 'tr') {
    i18nMessages = messages_tr;
  }

  return (
    <IntlProvider locale={langKey} messages={i18nMessages}>
      <Seo />
      <Nav isHome={isHome}
        homeLink={homeLink}
        langs={langsMenu}
        url={url}
        currentLang={langKey}
      />
      <main className="main-body">
        <div className="uk-section">
          <div className="uk-container"> 
            {children}
          </div>
        </div>
      </main>
      <Footer />
    </IntlProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

export default Layout